import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {WidgetType} from 'api/entities';
import {Observable} from 'rxjs';
import {apiVersion} from '../../../api-version.constant';
import {getCacheHeaders} from '../shared.service';

@Injectable({providedIn: 'root'})
export class QrNavigationEntity {
  constructor(private http: HttpClient) {}

  downloadDashboardQRCode(dashboardId: number): Observable<Blob> {
    return this.http.get(apiVersion + 'dashboards/' + dashboardId + '/qrcode', {
      headers: getCacheHeaders(false).headers,
      responseType: 'blob'
    });
  }

  getDashboardNavigationParams(dashboardId: number): Observable<{groupId: number; clientId: number}> {
    return this.http.get<{groupId: number; clientId: number}>(apiVersion + 'dashboards/' + dashboardId + '/navigation');
  }

  downloadWidgetQRCode(widgetId: number, dataPointFilterId: number): Observable<Blob> {
    return this.http.get(apiVersion + 'widgets/' + widgetId + '/qrcode' + (dataPointFilterId ? '?dataPointFilterId=' + dataPointFilterId : ''), {
      headers: getCacheHeaders(false).headers,
      responseType: 'blob'
    });
  }

  getWidgetNavigationParams(widgetId: number): Observable<{groupId: number; clientId: number; dashboardId: number; widgetType: WidgetType}> {
    return this.http.get<{groupId: number; clientId: number; dashboardId: number; widgetType: WidgetType}>(
      apiVersion + 'widgets/' + widgetId + '/navigation'
    );
  }
}
